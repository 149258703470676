
import { Notify } from 'quasar'
import { reactive } from 'vue'

const Geolocation = reactive({

	supported: (navigator.geolocation? true:false),
	enabled: false, 	  

	toggle() {
		console.log('Gelocation.toggle',{supported:this.supported,enabled:this.enabled})
		if ( this.supported ) {
			this.enabled = !this.enabled	
			if ( this.enabled ) {
				this.position()
			}
		} else {
			Notify.create({
				color:'negative',
				message:'Gelocation is not supported on this browser'
			})
		}
	},

	position() {
		return new Promise((resolve,reject)=>{
			if ( this.supported ) {
 				navigator.geolocation.getCurrentPosition((position)=>{
 					console.log(position.coords)
 					resolve({
 						coords:{
 							latitude:position.coords.latitude,
 							longitude:position.coords.longitude,
 							altitude:position.coords.altitude,
 							heading:position.coords.heading,
 							speed:position.coords.speed,
 							accuracy:position.coords.accuracy
 						},
 						timestamp:position.timestamp
 					})
 				},(err)=>{
 					reject(err)
 				})				
			}
		})
	}

})

// console.log('Gelocation',{supported:Geolocation.supported,enabled:Geolocation.enabled})

export default Geolocation