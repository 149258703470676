
import { api } from 'boot/axios'
import { Screen } from 'quasar'
import { reactive } from 'vue'

import Events from './events'

export const store = reactive({

  // TODO - CHANGE THIS TO COOKIE
  token: localStorage.getItem('token'),

  initialized: false,
  layout: {
    left: {
      open: false,
      mini: false,
      component: false,
      components: [],
    },
    right: {
      open: false,
      mini: false,
      component: false,
      components: [],
    }
  },
  user: false,
  search: false,
  dashboard: {
    user: false,
    events: [],
    people: [],
    places: [],
    things: [],
    messages: [],
    activity: [],
    bookmarks: [],
    initialized: false
  }
})

export const getters = {
  isMobile() {
    return Screen.lt.sm
  },
  layout (dir) {
    return store.layout[dir]
  },
  user () {
    return store.user
  },
  search() {
    return store.search
  },
  maskFor(item) {
    if ( store.user && item && item.auth ) {
      let urls = store.user.auth.map((e)=>{return e.url}).concat([store.user.url])
      let e = item.auth.find((e)=>{return urls.includes(e.url)})
      return e? e.mask : 0
    } else return 0
  }
}

export const setters = {
  setSearch( results ) {
    store.search = results
  },
  toggleDrawer(dir) {
    const bucket = store.layout[dir]
    bucket.open = !bucket.open
  },
  setDrawer: (dir, spec) => {
    const bucket = store.layout[dir]
    const setSpec = (spec) => {
      bucket.open = false
      if ( spec ) {
        bucket.width = getters.isMobile()? 1.0 : (spec.width || 0.4)
        bucket.spec = spec
        bucket.open = true
      } else {
        bucket.open = false
        bucket.spec = false
        bucket.width = getters.isMobile()? 1.0 : 0.4
      }
    }
    if ( spec ) {
      bucket.components.push(spec)
      setSpec(spec)
    } else {
      bucket.components.pop() // existing
      spec = bucket.components.pop()
      setSpec(spec)
    }
  }
}

// collections managed by event service
// events.on('ita:type:event', store.dashboard.events)
// events.on('ita:type:activity', store.dashboard.activity)
// events.on('ita:type:bookmark', store.dashboard.bookmarks)

export const init = async (force) => {
  if ( !store.initialized || force ) {
    try {
      const resp = await api.get('/api/user')
      store.user = resp.data
      store.initialized = true        
      Events.connect( store.user )        
    } catch (err) {
      console.error(err)
    }
  }
}
