const routes = [
  {
    path: '/',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Landing.vue') }
    ]
  },
  {
    path: '/login',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Login.vue') }
    ]
  },
  {
    path: '/register',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Register.vue') }
    ]
  },
  {
    path: '/dash',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('components/Dashboard') }
    ]
  },
  {
    path: '/settings',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('components/Settings') }
    ]
  },  
  {
    path: '/profile',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('pages/Profile.vue') }
    ]
  },
  {
    path: '/graph',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('components/Graph') }
    ]
  },
  {
    path: '/clipboard',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('pages/Clipboard') }
    ]
  },  
  {
    path: '/recycling',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('pages/Recycling') }
    ]
  },    
  {
    path: '/:type',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('components/Content') }
    ]
  },
  {
    name: 'Detail',
    path: '/:type/:id',
    component: () => import('layouts/MemberLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('components/Content') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
