
import Geolocation from './geolocation'
import { init, store } from './store'
import { api, session } from 'boot/axios'

const authenticated = () => {
  return store.token
}

const setToken = (token) => {
  localStorage.setItem('token', token)
  store.token = token
}

const login = (email, password) => {
  return session.post('/api/session', { email: email, password: password })
    .then(async (resp) => {
      console.log('login', resp)
      if (resp.status === 200) {
        setToken(resp.data.token)
        await init(true)
        return resp
      } else throw new Error('denied')
    })
}

const register = async (email, password, invitation) => {
  return session.post('/api/user', { email, password, invitation })
    .then(async (resp) => {
      console.log('register returned',resp.data)
      if (resp.status === 200) {
        setToken(resp.data.token)
        await init()
        return resp.data
      } this.errMsg = 'Please try again'
    })
}

const logout = () => {
  return api.delete('/api/session')
    .then((resp) => {
      localStorage.removeItem('token')
      return resp
    })
}

const dashboard = () => {
  return api.get('/api/dashboard')
}

const user = () => {
  return api.get('/api/user')
  .then((resp)=>{
    return resp.data
  })
}

const find = (pattern, options) => {

  if ( !pattern.url && !pattern.type && !pattern.mixin && !pattern.search ) {
    const msg = `find requires type or url or mixin or search : ${JSON.stringify(pattern,0,1)}`
    throw new Error(msg)
  }
  return api.get('/api/find', {
    params: {
      pattern: pattern,
      options: options || {},
      context: document.location.href.split('#/').pop()
    }
  }).then((response) => {
    return response.data
  })
}

const create = async (values,aspect) => {

  let position

  if ( Geolocation.enabled ) {
    try {
      position = await Geolocation.position()
    } catch ( err ) {
      console.error(err)
    }
  }

  if ( aspect ) {
    return api.post(`/api/${aspect}`, {values,position})
  } else return api.post('/api/create', {values,position})
}

const update = (pattern, updates) => {
  return api.post('/api/update', {
    pattern,updates
  })
}

/**
@param subject - url to be removed
@param context - url of context
**/
const remove = function (pattern, context, options) {
  console.log('api.remove', pattern, context)
  return api.post('/api/remove',{pattern,context,options})
}

const graph = function (options) {
  return api.post('/api/graph', options)
}

const geo = function( address ) {
  return api.post('/api/geo',{ address:address } )
}

const transform = function( src, tgt, options ) {
  return api.post('/api/transform',{ src, tgt, options } )
}

// TODO, refactor

export const actions = {
  authenticated,
  dashboard,
  transform,
  register,
  logout,
  create,
  update,
  remove,  
  login,
  graph,
  find,
  user,
  geo
}
