

const managed = []
let SOCKET 

const bucketFor = (type, list=[], action) => {

  let b = managed.find((b) => { return b.type === type })
  if (!b ) {
    b = { type: type, list: [] }
    managed.push(b)
  }

  list.forEach((item)=>{
    const idx = b.list.indexOf(item)
    if ( idx === -1 && action === 'on' ) {
      b.list.push(item)
    } else if ( idx !== -1 && action ==='off' ) {
      b.list.splice(idx,1)
    }
  })

  return b
}

class EventBus {

  static on (type, list) {
    // console.log('events managing', type, JSON.stringify(list,0,1) )
    bucketFor(type, list, 'on')
  }

  static any (fn,id) {
    const subs = { id: id, type: 'any', fn: fn }
    managed.push(subs)
    //console.log('Events.any',id,managed.length)
  }

  static none (id) {
    const idx = managed.findIndex((s)=>{return s.id===id})
    if ( idx !== -1 ) managed.splice(idx,1)
    //console.log('Events.none',id,managed.length)
  }

  static off (type, list) {
    bucketFor(type, list, 'off')
  }

  static onItem(item) {
    if ( item ) {
      const type = item.url.split('/')[0]
      EventBus.on(type,[item])
      //console.log('Events.onItem',item.url)
    }
  }

  static offItem(item) {
    if ( item ) {
      const type = item.url.split('/')[0]
      EventBus.off(type,[item])
      //console.log('Events.offItem',item.url)
    }
  }

  static async emit (event) {

    setTimeout(()=>{

      // emit for 'any' buckets
      const buckets = managed.filter((m) => { return m.type === 'any' })
      buckets.forEach((b) => { b.fn(event) })

      // manage typed buckets
      const type = event.item.type ? event.item.type : event.item.url.split('/')[0]
      const bucket = bucketFor(type)
      // console.log('events.emit processing', event, bucket )

      if (bucket) {
        const item = bucket.list.find((i) => { return i.url === event.item.url })
        if (item && event.method === 'take') {
          bucket.list.splice(bucket.list.indexOf(item), 1)
        } else if (!item && event.method === 'put') {
          bucket.list.push(event.item)
        } else if (item && event.method === 'modify') {
          console.log('processing modify', JSON.stringify(event,0,1), JSON.stringify(item,0,1))
          Object.keys(event.item).forEach((key) => {
            if (key.includes('#')) {
              const val = event.item[key]
              console.log('processing key', key, val)
              if ( val === '$undefined' ) {
                delete item[key]
              } else {
                item[key] = val;
              }
            }
          })
        }
      }
    })
  }  

  static connect(user) {

    try {

    const origin = user.auth.filter((e)=>{return e.url !== 'ita:type:group/users'}).map((e)=>{return e.url})

    SOCKET = io((process.env.VUE_APP_API_ENDPOINT || '/'),{ query: { origin: origin, withCredentials:true } })

    SOCKET.on('connect', function () {
      console.log('socket connected!',origin)
    })

    SOCKET.on('event', function (e) {
      // console.log('socket.event', origin, JSON.stringify(e,0,1))
      if (e.item && e.item.url) {
        EventBus.emit(e)
      }
    })

    SOCKET.on('disconnect', function (socket) { 
      console.log('socket.disconnect', origin, socket.id) 
    })

    } catch( err ) {
      console.error(err)
    }

    /**

    console.log('connect', io.socket)

      function connected() {
          console.log('socket connected',user.token)
          io.socket.get('/connect?token='+user.token)
          io.socket.on('message', function(msg) {
              var context = msg.data && msg.data.ancestors && msg.data.ancestors.length? msg.data.ancestors[0].url : false
              var event = {
                  method:msg.method,
                  item:msg.data,
                  context:context
              }
              console.log('emitting',event)
              Events.$emit('content-event', event )
          })
      }

      if ( io.socket._raw && io.socket._raw.connected ) {
          connected()
      } else {
          io.socket.on('connect', connected )
      }

      **/

  }

  static disconnect() {
    console.log('disconnecting')
    if (SOCKET) SOCKET.disconnect()
  }  


}

export default EventBus
