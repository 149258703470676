// src/boot/axios.js

import { boot } from 'quasar/wrappers'
import axios from 'axios'

console.log('axios using', process.env.VUE_APP_API_ENDPOINT)

const session = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT || '/',
  headers: {
    'Cache-Control': 'no-cache'
  }
})

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT || '/',
  headers: {
    'Cache-Control': 'no-cache'
  }
})

api.interceptors.response.use(response => {
  return response
}, err => {
  console.error('interceptor err', document.location.pathname) // 'Authorization',localStorage.getItem('token'))
  let ignored = ['/login','/register']
  if ((err.response.status === 401 || err.response.status === 403) ) {
    if ( !ignored.includes(document.location.pathname) ) {
      document.location.href='/#/login' 
      throw err.response
    }
  }
  return Promise.reject(err.response)
})

/** apply authorization header on every request **/
api.interceptors.request.use(request => {
  request.withCredentials = true
  // request.headers.Authorization = localStorage.getItem('token')
  request.headers['ngrok-skip-browser-warning'] = true  
  return request
})

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API


  app.config.globalProperties.$session = session
})

export { axios, api, session }
